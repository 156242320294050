/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//Home Page
const greeting = {
  title: "Hi all, I'm Vivek",
  logo_name: "Vivek Sonar",
  nickname: "vivek sonar",
  subTitle:
    "Product Manger with one year of experince working in FinTech domain. And I love startups",
  resumeLink:
    "https://drive.google.com/file/d/1_j6qE2oLMYOT7BQKKa7BfSihOWUCEDXz/view?usp=sharing",
  portfolio_repository: "https://github.com/viveksonar",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/viveksonar",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/vivek-sonar-266b2417b/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Mail",
    link: "mailto:vivek@viveksonar.in",
    fontAwesomeIcon: "fa-mailchimp", // Reference https://fontawesome.com/icons/mailchimp?style=brands
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Gmail",
    link: "mailto:vivekksonarjobs@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Medium",
    link: "https://www.medium.com/@vivekksonar2000",
    fontAwesomeIcon: "fa-medium-m", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Twitter",
    link: "https://twitter.com/viveksonar2000",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
];

const skills = {
  data: [
    {
      title: "Product Managment",
      fileName: "FullStackImg",
      skills: [
        "⚡ Love to be on the customer facing side of the application and take decissions that make postive impact for customers",
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Analyzing data and taking data-driven business decisions",
        "⚡ Manage team of Engineers to build new features that make life of customers more easy",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
    {
      title: "Cloud Architecture/ Site Realibility Engineering",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working/Architecting on multiple cloud platforms including AWS and GCP",
        "⚡ Experience working with Cloud-Native Technology like Docker, Kubernetes",
        "⚡ Experience developing, deploying, and maintaining highly available Kubernetes applications",
        "⚡ Experience with maintaining/developing CI/CD pipelines on Jenkins and CircleCI and configuration management tools like Terraform",
        "⚡ Love to develop applications using a serverless stack of AWS and GCP",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
      ],
    },

    {
      title: "Full Stack Development",
      fileName: "DesignImg",
      skills: [
        "⚡ Building resposive website front end using HTML, CSS & React-Redux",
        "⚡ Creating application backend in Node & Express",
        "⚡ Learning to build Full Stack applications using MERN stack",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      // profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      // profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      // profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      // profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   // profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Modern Education Societies College Of Engineering Pune",
      subtitle: "Bachelors in Computer Engineering",
      logo_path: "mescoe.png",
      alt_name: "MESCOE Pune",
      duration: "2018 - Present",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CN, etc.",
        "⚡ Apart from this, I have done courses on Cloud Computing, Cloud Native Application Development and Full Stack Development",
        "⚡ I have passed Microsoft Azure AZ-900 exam along with Oracle Cloud Infrastructure Foundations 2020 Associate and preparing for AWS Solutions Architect Associate and GCP Associate Cloud Enginner next",
      ],
      website_link: "http://www.mescoepune.org/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "AWS Certified Solutions Architect- Associate",
      subtitle: "- AWS",
      logo_path: "AWS-SolArchitect-Associate-2020.png",
      certificate_link:
        "https://www.youracclaim.com/badges/33279df5-2d85-43af-a686-7aa533c9723e/public_url",
      alt_name: "AWS",
      color_code: "#FFFFFF",
    },
    {
      title: "Microsoft Certified: Azure Fundamentals",
      subtitle: "- Microsoft",
      logo_path: "MicrosoftAcclaim.png",
      certificate_link:
        "https://www.youracclaim.com/badges/0addb823-cc49-4868-a814-1896c0cd1f86",
      alt_name: "Microsoft",
      color_code: "#FFFFFF",
    },
    {
      title: "OCI Foundations 2020 Certified Associate",
      subtitle: "- Oracle",
      logo_path: "Oracle-Acclaim.png",
      certificate_link:
        "https://www.youracclaim.com/badges/bc1285f7-ea1d-40cc-8ba7-e048ab7576f5/public_url",
      alt_name: "Oracle",
      color_code: "#FFFFFF",
    },
    {
      title: "Google Kubernetees Engine(GKE) Professional",
      subtitle: "- Acloud Guru",
      logo_path: "A-Cloud-Guru.png",
      certificate_link: "https://verify.acloud.guru/656A318681BF",
      alt_name: "Acloud Guru",
      color_code: "#020335",
    },
    // {
    //   title: "Cloud Computing Concepts",
    //   subtitle: "- Coursera",
    //   logo_path: "coursera_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/certificate/FNBPT657AMKV",
    //   alt_name: "University of ILLINOIS",
    //   color_code: "#2A73CC",
    // },
    {
      title: "Docker Fundamentals",
      subtitle: "- Acloud Guru",
      logo_path: "A-Cloud-Guru.png",
      certificate_link: "https://verify.acloud.guru/B4A8569375A5",
      alt_name: "Acloud Guru",
      color_code: "#020335",
    },
    {
      title: "Introduction to Ansible",
      subtitle: "- Cloud Academy",
      logo_path: "cloud_academy.png",
      certificate_link:
        "https://www.credential.net/42c74718-861d-4b33-9e42-00dbce940b06",
      alt_name: "Cloud Academy",
      color_code: "#FFFFFF",
    },
    {
      title: "Hands-on Cloud Labs",
      subtitle: "- Qwiklabs",
      logo_path: "qwiklabs.png",
      certificate_link:
        "https://www.qwiklabs.com/public_profiles/7b031736-3748-4f05-85af-3527b180da0e",
      alt_name: "GCP",
      color_code: "#FFFFFF",
    },
    // {
    //   title: "Advanced ML on GCP",
    //   subtitle: "- GCP Training",
    //   logo_path: "google_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
    //   alt_name: "Google",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "DL on Tensorflow",
    //   subtitle: "- Laurence Moroney",
    //   logo_path: "deeplearning_ai_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
    //   alt_name: "deeplearning.ai",
    //   color_code: "#00000099",
    // },
    // {
    //   title: "Fullstack Development",
    //   subtitle: "- Jogesh Muppala",
    //   logo_path: "coursera_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
    //   alt_name: "Coursera",
    //   color_code: "#2A73CC",
    // },
    // {
    //   title: "Kuberenetes on GCP",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
    // {
    //   title: "Cryptography",
    //   subtitle: "- Saurabh Mukhopadhyay",
    //   logo_path: "nptel_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
    //   alt_name: "NPTEL",
    //   color_code: "#FFBB0099",
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Internships",
  description:
    "Currently working at PayU building 0-1 product for SMBs. I have some freelance experience driving growth for techstartups by writing crisp & concise technical content. I love to work in an environment where I can wear multiple caps at the same time.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Internships",
      experiences: [
        {
          title: "Associate Product Manager",
          company: "PayU",
          company_url: "https://payu.in/",
          logo_path: "PayU.jpg",
          duration: "Nov 2021 - Present (6 Months)",
          location: "Remote, Banglore",
          description:
            "• Developed a smooth bill creation pipeline which increased business metrics by 9%.",
          description2:
            "• Designed a smooth flow for on-boarding users on the lending product by collaborating with different teams.",
          description3:
            "• Delivered a internal tool for Operations Credit Risk team to identify potential users and block the ineligible/risky users from taking credit.",
          description4:
            "• Managing the entire product analytic suite and creating effective dashboards for stakeholders to get product insights.",
          description5:
            "• Delivered a help support channel for users to ask questions and raise queries about the product.",
          color: "#0879bf",
        },
        {
          title: "Associate Product Manager",
          company: "Adakerja",
          company_url: "https://adakerja.com/",
          logo_path: "adakerja.png",
          duration: "Jun 2021 - Nov 2021 (6 Months)",
          location: "Remote, Singapore",
          description:
            "• Increased userretention by 11% by improving the User Experience for product onboarding.",
          description2:
            "• Delivered an automated and smooth sign-up flow for B2B app by collaborating with Designers and Engineers.",
          description3:
            "• Conducted a detailed competitive analysis and productresearch report to gain insights into opportunities and threats.",
          description4:
            "• Designed Indian version of Facebook and Whatsapp onboarding bot forthe B2C app.",
          color: "#0879bf",
        },
        {
          title: "Cloud / Devops Engineer",
          company: "Adakerja",
          company_url: "https://adakerja.com/",
          logo_path: "adakerja.png",
          duration: "May 2020 - Jun 2021 (12 Months)",
          location: "Remote, Singapore",
          description:
            "• Overlooked, Monitored, and maintained the Cloud Infrastructure of Cloud Infrastructure of Company (AWS).",
          description2:
            "• Worked with EC2 (Elastic Compute Cloud) EKS (Elastic Kubernetes Service) migration.",
          description3:
            "• Reduced deployment time by 80%, by constructing a CI/CD pipelinefor automated deployment of our two static website hosted on EC2 instances and to roll out API documentation hosted on Kubernetes.",
          description4:
            "• Increased efficiency and speed of deployment by reducing the size of containers by 91% using Docker Multi stage builds.",
          description5:
            "• Improved monitoring of Kubernetes cluster using Grafana with Prometheus. Improved the testing efficiency for the app by creating a staging database having similar data like production data.",
          description6:
            "• Worked with ElasticSearch service to setup Robust Elastic Search Cluster with Kibana for logging/monitoring contianer workloads for production clusters",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Startup",
      experiences: [
        {
          title: "SHARPDOX",
          company: "Technical Content Production Business",
          // company_url: "https://www.linkedin.com/company/dscmescoe/",
          logo_path: "dsc_logo.png",
          duration: "Jan 2021 - Present",
          location: "India",
          description:
            "• Helped more than 15 Tech Startups Produce High-Quality Technical content.",
          description2:
            "• Major names include, SUSE Linux, Fauna, Earthly.dev (2K GitHub stars), LogRocket, CircleCI, CPrime, etc",
          description3: "• Total view count of the articles is more than 100k.",
          description4: "• Made around $10000+ in revenue till date",
          color: "#0C9D58",
        },
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Developer Students Club Member",
    //       company: "DSC MESCOE PUNE",
    //       company_url: "https://www.linkedin.com/company/dscmescoe/",
    //       logo_path: "dsc_logo.png",
    //       duration: "OCT 2019 - OCT 2019",
    //       location: "Pune",
    //       description:
    //         "Volunteer for Google Cloud Study Jam organized by Developer students club M.E.S.C.O.E. Over 500 students where involved in the event.Student where given basic understanding of Google cloud in 3 particular domain including App development,Machine Learning, Data Science.",
    //       color: "#0C9D58",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I love to build cloud native projects and I try to use different tech stack for better understanding of everything. Currently working on building a Serverless Full Stack Ecommerces Shop which will be available on AWS",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profilePicture500x500.png",
    description:
      "DISCUSS A PROJECT OR JUST WANT TO SAY HI? MY INBOX IS OPEN FOR ALL.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to write about new things I learn(Mainly realted to cloud) whenever possible.",
    link: "https://blog.viveksonar.in",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Panvel, Maharashtra, India",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/gaSquCL7B5zi3iEh7",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 9511717923",
  },
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
